import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass/styled-components';
import Link from '../Link';

const LegalLink = ({ target, href, external, children }) => {
  if (external) {
    return (
      <Button
        sx={{ color: 'text.subtle' }}
        py={1}
        className="theme_only"
        as="a"
        href={href}
        fontSize={0}
        target={target}
        variant="getroLink"
      >
        {children}
      </Button>
    );
  }

  return (
    <Link shallow href={`${href}#content`} passHref scroll target={target} exact>
      <Button sx={{ color: 'text.subtle' }} py={1} className="theme_only" as="a" fontSize={0} variant="getroLink">
        {children}
      </Button>
    </Link>
  );
};

LegalLink.propTypes = {
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  target: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

LegalLink.defaultProps = {
  target: null,
  children: null,
  external: false,
};

export default LegalLink;
