import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

// note: the width is to be 1136 and px=3. don't change it - Jacob
const Container = ({ children, position, ...rebassProps }) => (
  <Box
    mx="auto"
    width={[1, 1, '1132px']}
    pt={0}
    px={3}
    sx={{
      mx: 'auto !important',
      boxSizing: 'border-box',
      maxWidth: '100%',
      position,
      zIndex: 1,
    }}
    data-testid="container"
    {...rebassProps}
  >
    {children}
  </Box>
);

Container.defaultProps = {
  position: 'static',
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
};

export default Container;
