import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { parseCookies, setCookie } from 'nookies';
import { userTokenAuthenticationRequest } from '../redux/actions/userActions';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import { isOnboardingPendingSelector, userSelector } from '../redux/selectors/user';

const useUser = ({ network }) => {
  const [tokenProcessed, setTokenProcessed] = useState(false);
  const isLoggingOut = useSelector(makeSelectIsLoading('logoutRequest'));
  const user = useSelector(userSelector);
  const isOnboardingPending = useSelector(isOnboardingPendingSelector);
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const { token } = query;

  useEffect(() => {
    if (token && !tokenProcessed) {
      setTokenProcessed(true);

      setCookie(null, 'token', token, {
        maxAge: 60 * 60 * 24,
        path: '/',
      });
      // delete router.query.token;
      delete router.query.token;
      router.replace({ pathname: router.pathname, query: router.query }, undefined, { shallow: true });
    }

    const { loading, loaded } = user;
    const cookies = parseCookies();
    if (!loading && !loaded && cookies.token && !isLoggingOut) {
      dispatch(
        userTokenAuthenticationRequest({
          network,
          token: cookies.token,
          source: 'cookies',
        }),
      );
    }
  }, [network, tokenProcessed, token, router, dispatch, user, query, isLoggingOut]);

  useEffect(() => {
    const { forcePasswordUpdate } = user;
    const { pathname } = router;

    if (isOnboardingPending && pathname !== '/onboarding') {
      router.push('/onboarding', undefined, { shallow: true });
    }

    if (forcePasswordUpdate && !isOnboardingPending && pathname !== '/password-reset') {
      router.push('/password-reset', undefined, { shallow: true });
    }
  }, [isOnboardingPending, router, user]);

  return [user];
};

export default useUser;
