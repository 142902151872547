import React, { useMemo } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import { isPreview } from '../../../helpers/analyticsHelper';
import { talentNetworkSelector } from '../../../redux/selectors/network';
import { NetworkSchema } from '../../../schema/network';
import { ProtocolSchema } from '../../../schema/protocol';

const SEO = ({ network, title, protocol, host, children, noIndex }) => {
  const talentNetwork = talentNetworkSelector(network);
  const {
    name,
    metaTitle,
    metaDescription,
    metaFaviconUrl,
    metaImageUrl,
    metaTwitterUsername,
    previewUrl,
    loaded: networkLoaded,
  } = network || {};

  const pageTitle = useMemo(() => {
    if (title && talentNetwork) return `${title} | ${name} Talent network`;
    if (title && !talentNetwork && networkLoaded) return `${title} | ${name} Job Board`;
    if (!networkLoaded) return 'Getro | Make the connection';
    if (metaTitle) return metaTitle;
    if (talentNetwork) return `${name} Talent network`;

    return `${name} Job Board`;
  }, [title, metaTitle, talentNetwork, name, networkLoaded]);

  const pageMetaTitle = useMemo(() => {
    if (!networkLoaded) return 'Getro | Make the connection';
    if (metaTitle) return metaTitle;
    if (talentNetwork) return `${name} Talent network`;

    return `${name} Job Board`;
  }, [metaTitle, talentNetwork, name, networkLoaded]);

  const jobBoardPreview = useMemo(() => isPreview(host, previewUrl, network?.url), [host, previewUrl, network]);
  const metaUrl = useMemo(() => {
    if (protocol && host) {
      return `${protocol}//${host}`;
    }

    return null;
  }, [host, protocol]);

  const pageDescription = useMemo(() => {
    if (metaDescription) return metaDescription;
    if (!networkLoaded)
      return 'A job search tool for proactive professionals looking for work that matters. The evolution of job boards.';

    return `Search job openings across the ${name} network.`;
  }, [metaDescription, name, networkLoaded]);

  if (!network) {
    return null;
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" property="og:description" content={pageDescription} key="og:description" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      <link rel="apple-touch-icon" sizes="180x180" href={metaFaviconUrl || '/favicons/apple-touch-icon.png'} />
      <link rel="icon" type="image/png" sizes="32x32" href={metaFaviconUrl || '/favicons/favicon-32x32.png'} />
      <link rel="icon" type="image/png" sizes="16x16" href={metaFaviconUrl || '/favicons/favicon-16x16.png'} />
      <link rel="shortcut icon" href={metaFaviconUrl || '/favicons/favicon.ico'} />

      {!networkLoaded && (
        <>
          <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
          <link rel="manifest" href="/favicons/manifest.json" />
          <link rel="shortcut icon" href="/favicons/favicon.ico" />
          <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
          <meta name="theme-color" content="#ffffff" />
        </>
      )}

      <meta name="url" property="og:url" key="og:url" content={metaUrl} />
      <meta name="type" property="og:type" content="website" key="og:type" />
      <meta name="title" property="og:title" content={pageMetaTitle} key="og:title" />

      {metaImageUrl && (
        <>
          <meta name="image" property="og:image" key="og:image" content={metaImageUrl.replace('https://', 'http://')} />
          <meta
            name="secure_url"
            property="og:image:secure_url"
            key="og:image:secure_url"
            content={metaImageUrl.replace('http://', 'https://')}
          />
        </>
      )}
      {!networkLoaded && (
        <>
          <meta name="image:width" property="og:image:width" key="og:image:width" content="1200" />
          <meta name="image:height" property="og:image:height" key="og:image:height" content="630" />
        </>
      )}

      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      {metaTwitterUsername && <meta name="twitter:site" content={metaTwitterUsername} key="twitter:site" />}
      <meta name="twitter:image" content={metaImageUrl} key="twitter:image" />
      <meta name="twitter:title" content={pageMetaTitle} key="twitter:title" />
      <meta name="twitter:description" content={pageDescription} key="twitter:description" />
      {metaTwitterUsername && <meta name="twitter:creator" content={metaTwitterUsername} key="twitter:creator" />}

      {(jobBoardPreview || !networkLoaded || noIndex) && <meta name="robots" content="noindex,nofollow" key="robots" />}

      <meta
        name="google-site-verification"
        content="CQNnZQRH0dvJgCqWABp64ULhLI69C53ULhWdJemAW_w"
        key="google-site-verification"
      />

      {children}
    </Head>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  protocol: ProtocolSchema,
  host: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  network: NetworkSchema,
  noIndex: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  children: null,
  protocol: null,
  host: null,
  network: null,
  noIndex: false,
};

export default SEO;
