import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

export const PageLoader = ({ color, size, display, ...rest }) => (
  <Box
    m="auto"
    sx={{
      width: size === 'sm' ? '24px' : '40px',
      height: size === 'sm' ? '24px' : '40px',
      borderWidth: size === 'sm' ? '3px' : '4px',
      borderRadius: 'circle',
      borderStyle: 'solid',
      borderColor: 'neutral.100',
      borderTopColor: color,
      animation: 'animationSpin 1s linear infinite',
    }}
    display={display}
    {...rest}
  />
);

PageLoader.propTypes = {
  display: PropTypes.oneOf(['inline', 'block']),
  size: PropTypes.oneOf(['sm', 'md']),
  color: PropTypes.string,
};

PageLoader.defaultProps = {
  display: 'block',
  size: 'md',
  color: 'customPrimary',
};
