import { useRouter } from 'next/router';

const useLoginWithRedirectUrl = () => {
  const router = useRouter();
  const { query } = router;
  let { pathname } = router;

  Object.keys(query).forEach((key) => {
    const keyLabel = `[${key}]`;
    if (pathname.indexOf(keyLabel) > -1) {
      pathname = pathname.replace(keyLabel, query[key]);
    }
  });

  return `/login?redirect=${pathname}`;
};

export default useLoginWithRedirectUrl;
