/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { pathToRegexp } from 'path-to-regexp';

const NewLink = ({ match, as, children, exact, href, ...props }) => {
  const { asPath: currentPath } = useRouter();
  // split at the ? to ignore query strings
  const path = useMemo(() => {
    const currentUrl = as || href;

    if (typeof currentUrl === 'string') {
      return currentUrl.split('#')[0];
    }

    const { pathname } = currentUrl;
    return pathname;
  }, [as, href]);

  let isActive = pathToRegexp(path, [], { sensitive: true, end: !!exact }).test(
    currentPath.split('#')[0].split('?')[0],
  );
  if (match && !exact) {
    isActive = match({ href });
  }

  const child = React.Children.only(children);

  let className = child.props.className || '';
  if (isActive) {
    className = `${className} active`;
  }

  return (
    <Link shallow href={href} as={as} {...props} legacyBehavior>
      {React.cloneElement(child, { className })}
    </Link>
  );
};

NewLink.propTypes = {
  match: PropTypes.func,
  as: PropTypes.string,
  children: PropTypes.element.isRequired,
  exact: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

NewLink.defaultProps = {
  match: null,
  as: null,
  exact: false,
};

export default NewLink;
