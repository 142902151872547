import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from '@getro/rombo';
import { Box, Flex, Link } from 'rebass/styled-components';
import LegalLink from '../LegalLink';

const Footer = ({ hasAdvancedDesign, networkLegal, privacyPolicyUrl, isExternalPrivacyPolicyUrl, onLogoClick }) => {
  const onGetroJobsClick = () => {
    onLogoClick();
  };

  return (
    <Flex
      className="powered-by-footer"
      data-testid="site-footer"
      justifySelf="flex-end"
      mt="auto"
      flexDirection="row"
      width={[1]}
      bg={hasAdvancedDesign ? 'transparent' : 'white'}
      py={4}
    >
      <Flex width={[1, '1100px']} mx="auto" flexDirection="column" textAlign="center">
        <Link
          alignSelf="center"
          mb={3}
          className="theme_only"
          href="https://www.getro.com/getro-jobs"
          target="_blank"
          data-testid="footer-getro-logo"
          onClick={onGetroJobsClick}
          color={hasAdvancedDesign ? 'text.dark' : 'primary'}
          width={['120px']}
        >
          <Box as={Logo} color="currentColor" showPoweredBy width="100%" height="auto" />
        </Link>
        <Flex flexDirection="row" justifyContent="center" flexWrap="wrap">
          <LegalLink
            href={privacyPolicyUrl}
            external={isExternalPrivacyPolicyUrl}
            target={isExternalPrivacyPolicyUrl ? '_blank' : '_self'}
          >
            Privacy policy
          </LegalLink>
          <LegalLink href="/cookie-policy">Cookie policy</LegalLink>
          {networkLegal && networkLegal.ccpaCompliant && (
            <LegalLink target="_blank" href={networkLegal.ccpaPolicyUrl} external>
              California privacy notice
            </LegalLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

Footer.propTypes = {
  hasAdvancedDesign: PropTypes.bool.isRequired,
  onLogoClick: PropTypes.func,
  networkLegal: PropTypes.shape({
    link: PropTypes.string,
    ccpaCompliant: PropTypes.bool.isRequired,
    ccpaPolicyUrl: PropTypes.string,
  }),
  privacyPolicyUrl: PropTypes.string.isRequired,
  isExternalPrivacyPolicyUrl: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  networkLegal: null,
  onLogoClick: () => {},
};

export default Footer;
